import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Impress = () => (
    <Layout hideHeader={true}>
        <SEO title="Impressum"/>
        <section className="page-section impress-section">
            <div className={"container"}>
                <h1 className={"text-center mt-0"}>Impressum</h1>
                <h3 className={"mt-0"}>Praxis Dr. med. Elke Natascha Heinitz</h3>
                <div className={"row"}>
                    <p>Bei der Kirche 3<br/> 23749 Grube</p>
                    <p>Tel: 04364-1011<br/> E-Mail: <a
                        href="mailto:info@hausarztpraxis-grube.de">info@hausarztpraxis-grube.de</a>
                    </p>
                    <p><strong>Praxisinhaber: </strong>
                        Dr. med. Elke Natascha Heinitz</p>
                    <p><strong>Berufsbezeichnung: </strong>
                        Fachärztin für Allgemeinmedizin, erworben und verliehen in Schleswig-Holstein, Bundesrepublik
                        Deutschland
                    </p>
                    <p>
                        <strong>Staatsangeh&ouml;rigkeit: </strong>
                        Deutsch
                    </p>
                    <p><strong>Mitglied der: </strong> <a href="http://www.aeksh.de/">&Auml;rztekammer
                        Schleswig-Holstein</a>
                        , K&ouml;rperschaft des &ouml;ffentlichen Rechts
                    </p>
                    <p><strong>Art: </strong>
                        freiberuflich niedergelassene Fach&auml;rztin
                    </p>
                    <p><strong>KV: </strong>
                        <a href="http://www.kvash.de/">Kassen&auml;rztliche Vereinigung
                            Schleswig-Holstein</a>
                    </p>
                    <p><strong>Umsatzsteuerpflicht: </strong>
                        nein (Angabe der Steuernr. daher lt. Verordnung des BMF nicht erforderlich)
                    </p>
                    <p><strong>Berufshaftpflichversicherung: </strong>
                        HdI Versicherung</p>
                    <p><strong>Konzept und Design: </strong>Jens Laur</p>
                    <p><strong>Fotografie: </strong>Fabian Hammerl </p>
                    <p><strong>Haftung:&nbsp;</strong>Die Angaben auf dieser Website erfolgen nur zu allgemeinen
                        Informationszwecken und stellen keine Beratung der Praxis Dr. med. Elke Natascha Heinitz dar.
                        Wir haben diese sorgf&auml;ltig gepr&uuml;ft, &uuml;bernehmen jedoch keinerlei Gew&auml;hr
                        f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der angebotenen
                        Informationen. Die Inhalte externer Websites, die &uuml;ber Hyperlinks von dieser Site aus
                        erreicht werden k&ouml;nnen oder die auf diese Seite verweisen, sind fremde Inhalte, auf die die
                        Praxis Dr. med. Elke Natascha Heinitz keinen Einfluss hat und f&uuml;r die die Praxis f&uuml;r
                        Dr. med Elke Natascha Heinitz keinerlei Gew&auml;hr &uuml;bernimmt. Die Vervielf&auml;ltigung
                        oder Verbreitung von auf dieser Website enthaltenen Angaben bedarf der vorherigen schriftlichen
                        Zustimmung der vorstehend im Impressum genannten verantwortlichen Personen.</p>
                    <p><strong>Haftungsausschluss:&nbsp;</strong>Die Praxis Dr. med. Elke Natascha Heinitz ist
                        bem&uuml;ht, die Angaben in ihrem Internetangebot stets auf einem aktuellen Stand zu halten.
                        Fehler im Bearbeitungsvorgang sind aber dennoch nicht auszuschlie&szlig;en. Eine Haftung oder
                        Garantie f&uuml;r die Aktualit&auml;t, Richtigkeit und Vollst&auml;ndigkeit der zur
                        Verf&uuml;gung gestellten Informationen ist daher ausgeschlossen. Insbesondere durch die
                        technischen Bedingungen des Internets als dezentraler Netzwerkverbund mit Zwischenspeicherung
                        und Weitergabe tempor&auml;rer Daten kann keine Gew&auml;hr f&uuml;r den Ausschluss von
                        Manipulationen, von versehentlichem Verf&auml;lschen oder f&uuml;r die Verwendung nur aktueller
                        Daten (z. B. alte Version noch im Cache) auf dem Verbindungsweg Anbieter zum Nutzer gegeben
                        werden. Auf diese systembedingte Einschr&auml;nkung wird hingewiesen. F&uuml;r die Richtigkeit
                        der Angaben und die &Uuml;bereinstimmung mit den Ursprungsdaten wird daher keine
                        Gew&auml;hr &uuml;bernommen. In keinem Fall wird f&uuml;r Sch&auml;den, die sich aus der
                        Verwendung der abgerufenen Informationen ergeben, eine Haftung &uuml;bernommen. S&auml;mtliche
                        Informationen oder Daten, ihre Nutzung sowie s&auml;mtliches mit den Internet-Seiten der
                        Hausarztpraxis Grube zusammenh&auml;ngende Tun, Dulden oder Unterlassen unterliegt
                        ausschlie&szlig;lich deutschem Recht.</p>
                    <p><strong>Hinweis zu externen Links:&nbsp;</strong>Die Praxis Dr. med. Elke Natascha Heinitz ist
                        als Anbieter von Informationen im Internet nach &sect; 8 Abs. 1 des Teledienstgesetzes (TDG)
                        bzw. &sect; 5 Medienstaatsvertrag (MDStV) f&uuml;r die &quot;eigenen Inhalte&quot;, die sie zur
                        Nutzung bereith&auml;lt, verantwortlich. Von diesen &quot;eigenen Inhalten&quot; sind
                        Querverweise (Links) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden.
                        Durch diese Querverweise erm&ouml;glicht es die Praxis Dr. med. Elke Natascha Heinitz Besuchern
                        ihres Internetangebotes, auf spezielle Angebote anderer Anbieter zuzugreifen. Diese Angebote
                        unterliegen nicht der Kontrolle der Praxis Dr. med. Elke Natascha Heinitz und k&ouml;nnen somit
                        nicht beeinflusst werden. Die Praxis Dr. med. Elke Natascha Heinitz hat bei der Aufnahme der
                        Links in ihr Internetangebot die fremden Inhalte gepr&uuml;ft. Da das Internet einem
                        st&auml;ndigen Wechsel unterworfen ist, kann sich der Inhalt der verlinkten Angebote
                        ver&auml;ndern, ohne dass die Hausarztpraxis Grube davon Kenntnis erlangt. Die Praxis Dr. med.
                        Elke Natascha Heinitz lehnt daher ausdr&uuml;cklich die Verantwortung f&uuml;r jegliche Inhalte,
                        die mittels eines Hyperlinks von fremden Anbietern zur Verf&uuml;gung gestellt werden, ab.</p>
                    <p><strong>Hinweis zu Formularen und E-Mail-Kommunikation:&nbsp;</strong>Die &Uuml;bertragung der im
                        Internetangebot der Praxis Dr. med. Elke Natascha Heinitz verwendeten Online-Formulare erfolgt,
                        wie ebenfalls bei der Versendung von E-Mails, unverschl&uuml;sselt. Es ist daher m&ouml;glich,
                        dass Ihre Daten von Unbefugten abgefangen und eingesehen bzw. ver&auml;ndert oder gel&ouml;scht
                        werden. Da die Praxis Dr. med. Elke Natascha Heinitz derzeit keine gesicherte Verbindung bzw.
                        Verschl&uuml;sselung der Daten anbieten kann, sollten Sie im Zweifelsfall, wenn Sie dieses
                        Risiko nicht eingehen wollen, einen anderen Kommunikationsweg w&auml;hlen. Ansonsten erfolgt
                        die &Uuml;bertragung der Daten auf eigenes Risiko.</p>
                    <p><strong>Copyright:&nbsp;</strong>Alle im Internetangebot der Praxis Dr. med. Elke Natascha
                        Heinitz ver&ouml;ffentlichten Inhalte (Layout, Bilder, Texte p.p.) sind urheberrechtlich
                        gesch&uuml;tzt, es gelten die gesetzlichen Copyright-Bestimmungen. Die Verwertung der Inhalte in
                        jeglicher Form ist ohne vorherige Zustimmung der Hausarztpraxis Grube nicht gestattet. Downloads
                        der Web-Seiten d&uuml;rfen nur f&uuml;r den privaten Gebrauch erfolgen. Jede unerlaubte
                        Verwendung kann straf- und zivilrechtlich verfolgt werden. Anfragen zur Verwendung von Inhalten
                        sind an die Hausarztpraxis Grube zu stellen.</p>
                    <hr/>
                    <h3>Datenschutzerkl&auml;rung f&uuml;r die Nutzung dieser Website&nbsp;</h3>
                    <p>Die Nutzung der Website ist bis auf die Nutzung &uuml;ber MediDate, Idana und E-Mail (siehe
                        unten) ohne Angabe personenbezogener Daten m&ouml;glich.</p>
                    <p>Soweit personenbezogene Daten (zum Beispiel, Name, Adresse oder Telefon) erhoben werden, erfolgt
                        dies, soweit m&ouml;glich, stets auf freiwilliger Basis.</p>
                    <p>Eine Weitergabe an Dritte erfolgt nur mit Ihrer ausdr&uuml;cklichen schriftlichen Zustimmung.</p>
                    <p>Die Daten&uuml;bertragung im Internet (z.B. per E-Mail) kann Sicherheitsl&uuml;cken
                        aufweisen.</p>
                    <p>Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff Dritter ist nicht m&ouml;glich.</p>
                    <p>&nbsp;</p>
                    <p><strong>VERANTWORTLICHKEIT F&Uuml;R DIE DATENVERARBEITUNG/ KONTAKTM&Ouml;GLICHKEIT</strong></p>
                    <p>Verantwortlich f&uuml;r die Datenverarbeitung nach der EU-Datenschutzgrundverordnung (DS-GVO)
                        ist:</p>
                    <p>Praxisname: Dr. Elke Natascha Heinitz, Bei der Kirche 3, 23749 Grube</p>
                    <p>Kontaktdaten: schriftlich an o.g. Adresse / Tel.:&nbsp;04364-1011</p>
                    <p>Sie erreichen die zust&auml;ndige Datenschutzbeauftragte unter den angegebenen Kontaktdaten.</p>
                    <p>&nbsp;</p>
                    <p><strong>COOKIES</strong></p>
                    <p>Als &bdquo;Cookies&ldquo; werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer
                        gespeichert werden. Innerhalb der Cookies k&ouml;nnen unterschiedliche Angaben gespeichert
                        werden. Ein Cookie dient prim&auml;r dazu, die Angaben zu einem Nutzer (bzw. dem Ger&auml;t auf
                        dem das Cookie gespeichert ist) w&auml;hrend oder auch nach seinem Besuch innerhalb eines
                        Onlineangebotes zu speichern. Als tempor&auml;re Cookies,
                        bzw. &bdquo;Session-Cookies&ldquo; oder &bdquo;transiente Cookies&ldquo;, werden Cookies
                        bezeichnet, die gel&ouml;scht werden, nachdem ein Nutzer ein Onlineangebot verl&auml;sst und
                        seinen Browser schlie&szlig;t. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in
                        einem Onlineshop oder ein Login-Status gespeichert werden.
                        Als &bdquo;permanent&ldquo; oder &bdquo;persistent&ldquo; werden Cookies bezeichnet, die auch
                        nach dem Schlie&szlig;en des Browsers gespeichert bleiben. So kann z.B. der Login-Status
                        gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso k&ouml;nnen in
                        einem solchen Cookie die Interessen der Nutzer gespeichert werden, die f&uuml;r
                        Reichweitenmessung oder Marketingzwecke verwendet werden.
                        Als &bdquo;Third-Party-Cookie&ldquo; werden Cookies bezeichnet, die von anderen Anbietern als
                        dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur
                        dessen Cookies sind spricht man von &bdquo;First-Party Cookies&ldquo;).<br/>&nbsp;<br/>&nbsp;Wir
                        k&ouml;nnen tempor&auml;re und permanente Cookies einsetzen und kl&auml;ren hier&uuml;ber im
                        Rahmen unserer Datenschutzerkl&auml;rung auf.<br/>&nbsp;<br/>&nbsp;Falls die Nutzer nicht
                        m&ouml;chten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
                        entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
                        Cookies k&ouml;nnen in den Systemeinstellungen des Browsers gel&ouml;scht werden. Der Ausschluss
                        von Cookies kann zu Funktionseinschr&auml;nkungen dieses Onlineangebotes f&uuml;hren.<br/>&nbsp;
                        <br/>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing
                        eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des
                        Trackings, &uuml;ber die US-amerikanische Seite&nbsp;<a href="http://www.aboutads.info/choices/"
                                                                                rel="noreferrer nofollow noopener"
                                                                                target="_blank">http://www.aboutads.info/choices/</a> oder
                        die EU-Seite&nbsp;<a href="http://www.youronlinechoices.com/" rel="noreferrer nofollow noopener"
                                             target="_blank">http://www.youronlinechoices.com/</a> erkl&auml;rt werden.
                        Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des
                        Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
                        dieses Onlineangebotes genutzt werden k&ouml;nnen.</p>
                    <p>&nbsp;</p>
                    <p><strong>SERVERDATEN</strong></p>
                    <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverf&uuml;gungstellung der
                        folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazit&auml;t,
                        Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen,
                        die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.&nbsp;<br/>&nbsp;<br/>Hierbei
                        verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
                        Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und
                        Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
                        effizienten und sicheren Zurverf&uuml;gungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1
                        lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</p>
                    <p>&nbsp;</p>
                    <p><strong>KONTAKTDATEN / E-MAIL</strong></p>
                    <p>Das Praxispersonal erhebt personenbezogene Daten, wenn diese uns bei einer Kontaktaufnahme (z.B.
                        per Kontaktformular oder E-Mail) freiwillig mitgeteilt werden. Pflichtfelder werden als solche
                        gekennzeichnet, da wir in diesen F&auml;llen die Daten zwingend zur Bearbeitung Ihrer
                        Kontaktaufnahme ben&ouml;tigen. Welche Daten erhoben werden, ist aus den jeweiligen
                        Eingabeseiten ersichtlich. Wir verwenden die von ihnen mitgeteilten Daten zur Bearbeitung Ihrer
                        Anfragen gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. b DSGVO. Nach vollst&auml;ndiger Abwicklung
                        Ihrer Anfrage werden Ihre Daten gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine
                        weitere Nutzung Ihrer Daten gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben
                        oder wir uns eine dar&uuml;ber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt
                        ist und &uuml;ber die wir Sie in dieser Erkl&auml;rung informieren.&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>ERHEBUNG VON ZUGRIFFSDATEN UND LOGFILES</strong></p>
                    <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne
                        des Art. 6 Abs. 1 lit. f. DSGVO Daten &uuml;ber jeden Zugriff auf den Server, auf dem sich
                        dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten geh&ouml;ren Name der
                        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, &uuml;bertragene Datenmenge,
                        Meldung &uuml;ber erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers,
                        Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.<br/>&nbsp;<br/>Logfile-Informationen
                        werden aus Sicherheitsgr&uuml;nden (z.B. zur Aufkl&auml;rung von Missbrauchs- oder
                        Betrugshandlungen) f&uuml;r die Dauer von maximal 7 Tagen gespeichert und danach gel&ouml;scht.
                        Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
                        endg&uuml;ltigen Kl&auml;rung des jeweiligen Vorfalls von der L&ouml;schung ausgenommen.</p>
                    <p>&nbsp;</p>
                    <p><strong>GOOGLE FONTS</strong></p>
                    <p>Wir binden die Schriftarten (&quot;Google Fonts&quot;) des Anbieters Google LLC, 1600
                        Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerkl&auml;rung:&nbsp;<a
                            href="https://www.google.com/policies/privacy/" rel="noreferrer nofollow noopener"
                            target="_blank">https://www.google.com/policies/privacy/</a>, Opt-Out:&nbsp;<a
                            href="https://adssettings.google.com/authenticated" rel="noreferrer nofollow noopener"
                            target="_blank">https://adssettings.google.com/authenticated</a>.</p>
                    <p>&nbsp;</p>
                    <p><strong>NUTZUNG DER BESTELLUNGEN / TERMINVERGABE &Uuml;BER MediDate&nbsp;</strong></p>
                    <p>Die MediSoftware bietet f&uuml;r die Arztpraxis-Software PRAXIS-PROGRAMM die zus&auml;tzliche
                        Web-Software MediDate zur Datenverarbeitung an, die ausschlie&szlig;lich &uuml;ber Web-Browser
                        zu erreichen ist und als PlugIn in den Webauftritt der nutzenden Arztpraxis eingebunden wird.
                        Die Anwendungen werden von der jeweiligen Arztpraxis angeboten und k&ouml;nnen von
                        nat&uuml;rlichen Personen (Nutzer) kostenlos genutzt werden.</p>
                    <p>Die pers&ouml;nlichen und medizinischen Daten, die durch die Eingabe in die Anwendungen erfasst
                        werden, sind sehr sensibel und werden daher mit h&ouml;chster Effektivit&auml;t und Sicherheit
                        bereits unmittelbar bei der Eingabe durch asymmetrische, zertifikatsbasierte
                        Verschl&uuml;sselungsverfahren gesch&uuml;tzt.</p>
                    <p>Die Datenschutzgesetze von Deutschland (wie das Telemediengesetz (TMG), das
                        Bundesdatenschutzgesetz (BDSG), die datenschutzrechtlichen Bestimmungen des
                        Telekommunikationsgesetzes (TKG)) und die gesetzgeberischen Vorgaben auf EU-Ebene
                        (Europ&auml;ische Datenschutz-Grundverordnung (EU-DSGVO)) sollen dabei sicherstellen, dass das
                        Selbstbestimmungsrecht auch beim Umgang mit den Daten der Nutzerzur Geltung kommt und die
                        Privatsph&auml;re jedes Einzelnen gewahrt bleibt.</p>
                    <p>MediSoftware als Hersteller der Web-Software MediDate sorgt f&uuml;r die Einhaltung dieser
                        Datenschutzgesetze und unterliegt der Kontrolle und Aufsicht des schleswig-holsteinischen
                        Unabh&auml;ngigen Landeszetrum f&uuml;r den Datenschutz (ULD).</p>
                    <p>So werden aufgrund der speziellen Sensibilit&auml;t der Nutzerdaten diese grunds&auml;tzlich mit
                        vom Bundesamt f&uuml;r Sicherheit in der Informationstechnik (BSI) in den Technischen
                        Richtlinien TR-02102</p>
                    <p>(https://www.bsi.bund.de/DE/Publikationen/TechnischeRichtlinien/tr02102/index_htm.html)</p>
                    <p>empfohlenen Verfahren kryptografisch gesichert und damit mit einem optimalen Sicherheitsstandard
                        gesch&uuml;tzt.</p>
                    <p>MediSoftware hat zu keinem Zeitpunkt Einblick in die Nutzerdaten sowie ein umfassendes</p>
                    <p>Datenschutz- und Sicherheitskonzept &uuml;ber den (1) Datenzugang, (2) Datenverarbeitung, (3)
                        Datentransfer und (4) Datenspeicherung erstellt, welches der Sensibilit&auml;t der Daten gerecht
                        wird.</p>
                    <p>&nbsp;</p>
                    <p><strong>1 Datenzugang in MediDate</strong></p>
                    <p>S&auml;mtliche Daten werden von dem jeweiligen Nutzer selbst angelegt und aktualisiert. Zur
                        eindeutigen Identifizierung der Nutzer wird lediglich ein Hash-Wert seiner E-Mail-Adresse in
                        MediDate gespeichert.</p>
                    <p>Diese Daten ben&ouml;tigt MediDate, um die Kommunikation zwischen Arztpraxis und Nutzer zuordnen
                        zu k&ouml;nnen (z.B. Terminbest&auml;tigung, Terminabsage, Terminverschiebung). Die Nutzer haben
                        zudem die M&ouml;glichkeit, je nach von der Arztpraxis freigeschalteter Anwendungen,
                        zus&auml;tzliche Informationen zu hinterlegen. Die Nutzer von MediDate entscheiden allein,
                        welche Daten sie eingeben und speichern. Der Nutzer/Kunde ist allerdings selber f&uuml;r die
                        Sicherheit seines eigenen Computers und seiner eigenen Software verantwortlich und f&uuml;r den
                        entsprechenden Schutz zu sorgen.</p>
                    <p>Alle vom Nutzer erfassten Daten bleiben solange verschl&uuml;sselt auf Systemen von MediSoftware
                        als Betreiber der MediDate-Plattform zwischengespeichert, bis die Arztpraxis, die die
                        Anwendungen anbietet, die (Buchungs-)Daten abgeholt und in das praxisinterne
                        EDV-System &uuml;bernommen sowie die Buchung best&auml;tigt hat.</p>
                    <p>Wenn der Nutzer das Nutzungsverh&auml;ltnis mit der Arztpraxis beenden m&ouml;chte &ndash; was
                        jederzeit m&ouml;glich ist &ndash; , wird das zugeh&ouml;rige Nutzerprofil innerhalb der Praxis
                        (im Rahmen der geltenden gesetzlichen Dokumentationspflichten des Heilberuflers)
                        einschlie&szlig;lich aller in MediDate hinterlegten Daten vollst&auml;ndig und unwiderruflich
                        gel&ouml;scht. Informationen (wie Termine oder Nachrichten), die der Nutzer an die Arztpraxis
                        versendet hat, bleiben als Eingangsnachrichten beim Arzt erhalten, da diese Informationen in den
                        Besitz des Arztes &uuml;bergegangen sind.</p>
                    <p>&nbsp;</p>
                    <p><strong>2 Generelle Datenverarbeitung</strong></p>
                    <p>Weder Angestellte der MediSoftware noch Dritte k&ouml;nnen aufgrund der asymmetrischen
                        Verschl&uuml;sselung (Public-/Private-Key-Verfahren) Zugriff auf Daten, die auf den Servern von
                        MediSoftware zwischengespeichert sind, erhalten.</p>
                    <p>Um bei einem Zugriff auf die Daten die Datumsangaben des letzten Zugriffes f&uuml;r den Nutzer
                        anzuzeigen und gegebenenfalls nach einer l&auml;ngeren Zeit der Nicht-Nutzung Erinnerungs- und
                        L&ouml;schungsroutinen einleiten zu k&ouml;nnen, werden diese Datumsangaben durch MediSoftware
                        verarbeitet. Es werden aber dazu keine betreffenden Nutzungs- bzw. Kundenprofile erstellt,
                        sondern dem Nutzer nur eine Art generelles Logbuch &uuml;ber seine vergangenen Anmeldungen
                        angezeigt. Dabei wird nicht gezeigt und/oder gespeichert, was der Nutzer/Kunden konkret getan
                        oder ver&auml;ndert hat, sondern es wird nur generell angezeigt, ob er im pers&ouml;nlichen
                        Benutzerkonto aktiv war.</p>
                    <p>Dar&uuml;ber hinaus verwendet MediDate &lsquo;Session-Cookies&rsquo;. Session-Cookies sind kleine
                        Dateien, die im Speicher des Rechners vom Nutzer/Kunden gespeichert werden, um das
                        Sitzungs-Konzept der MediDate- Plattform zu unterst&uuml;tzen. Es handelt sich dabei nur um so
                        genannte tempor&auml;re Dateien, die automatisch wieder gel&ouml;scht werden, nachdem der Nutzer
                        die aktuelle MediDate-Sitzung beendet hat. In keinem Fall erh&auml;lt eine dritte Partei die
                        M&ouml;glichkeit, &uuml;ber die Internet-Seiten von MediDate Cookies bei den Nutzern/Kunden zu
                        hinterlegen.</p>
                    <p>Die personenbezogenen Angaben der Nutzer werden nicht an Dritte weitergegeben, wenn der Nutzer
                        dazu nicht ausdr&uuml;cklich sein Einverst&auml;ndnis erteilt hat oder MediSoftware zur
                        Herausgabe verpflichtet ist, beispielsweise aufgrund einer gerichtlichen oder beh&ouml;rdlichen
                        Anordnung.</p>
                    <p>&nbsp;</p>
                    <p><strong>3 Verschl&uuml;sselung des Datentransfers bzw. der Kommunikation</strong></p>
                    <p>Der Transfer der Daten vom Computer des Nutzers zum MediDate-Server wie auch umgekehrt das
                        Herunterladen von Daten vom MediDate-Server auf den Computer des Nutzers ist
                        verschl&uuml;sselt.</p>
                    <p>MediDate benutzt hierf&uuml;r den aktuellen Verschl&uuml;sselungsstandard SSL (Secure Socket
                        Layer, Version 3.0).</p>
                    <p>Dieses bew&auml;hrte kryptographische Verfahren wird weltweit als Standard f&uuml;r hochsensible
                        Transaktionen im Internet verwendet, wie beispielsweise im Online-Banking und bei sensiblen
                        Internet-Anwendungen im Gesundheitsbereich. Es kombiniert einen 2048 Bit
                        langen &ouml;ffentlichen Schl&uuml;ssel mit einem symmetrischen Schl&uuml;ssel in der L&auml;nge
                        von 256 Bit. Am Schlosssymbol im Browser-Fenster ist erkennbar, ob dieInformationen
                        gesch&uuml;tzt &uuml;bermittelt werden und welche Verschl&uuml;sselungsl.nge im Browser
                        unterst&uuml;tzt wird. Die Authentizit&auml;t des Verschl&uuml;sselungscodes von MediDate wird
                        durch das SSL-Zertifikat best&auml;tigt.</p>
                    <p>&Uuml;ber einen Doppelklick auf das Schl&uuml;sselsymbol am unteren Bildrand erf&auml;hrt der
                        Nutzer mehr &uuml;ber das Zertifikat.</p>
                    <p>&nbsp;</p>
                    <p><strong>4 Verschl&uuml;sselung der Datenspeicherung</strong></p>
                    <p>Die Daten der Nutzer werden nicht nur verschl&uuml;sselt &uuml;bermittelt, sondern auch
                        ausschlie&szlig;lich</p>
                    <p>verschl&uuml;sselt gespeichert. Dadurch wird der Zugriff auf die Nutzerdaten durch Dritte nicht
                        nur</p>
                    <p>organisatorisch, sondern auch technisch verhindert. Die Daten des Patienten werden bereits lokal
                        auf dem Rechner des Patienten verschl&uuml;sselt und erst danach zu den
                        MediDate-Servern &uuml;bertragen. Daf&uuml;r wird ein mehrstufiges System aus symmetrischen und
                        asymmetrischen Verschl&uuml;sselungsalgorithmen verwendet, so dass weder Administratoren und
                        Mitarbeiter von MediSoftware noch Dritte diese Daten im Klartext lesen k&ouml;nnen. Zum
                        Entschl&uuml;sseln der daf&uuml;r n&ouml;tigen Schl&uuml;ssel ist es zwingend n&ouml;tig, den
                        privaten Schl&uuml;ssel der Arztpraxis zu kennen. S&auml;mtliche verwendeten
                        Kryptographierungs-Algorithmen entsprechen</p>
                    <p>der Technischen Richtlinie TR-02102 (&bdquo;Kryptographische Verfahren: Empfehlungen und
                        Schl&uuml;ssellungen&ldquo;) des Bundesamtes f&uuml;r Sicherheit in der Informationstechnik.</p>
                    <p>Zur sicheren, physischen Verwahrung der Nutzer/Kunden Daten arbeitet MediSoftware mit einem
                        externen, gro&szlig;en Internet-Provider (derzeit: Microsoft Deutschland GmbH,
                        Siemensstra&szlig;e 27 &middot; 61352 Bad Homburg). Die genutzten Server befinden sich
                        ausschlie&szlig;lich in Europa. Nur besonders autorisierte Personen (etwa von
                        Service-Unternehmen zu Wartungsarbeiten) haben Zugang zu den gesicherten R&auml;umen. Aufgrund
                        der verschl&uuml;sselten Datenspeicherung k&ouml;nnen diese Personen nicht auf die Kunden-
                        /Nutzerdaten zugreifen. Der Provider gew&auml;hrleistet den Einsatz moderner
                        Firewall-Technologie und physikalisch gesicherter Einrichtungen.</p>
                    <p>Der Aufgabenbereich des Providers beinhaltet lediglich die Sicherstellung der Verf&uuml;gbarkeit
                        der Infrastruktur des Rechenzentrums (Strom, Internet, Routing) sowie der gemieteten Hardware
                        (z.B. Austausch defekter Komponenten). Auf die Server selbst haben nur Administratoren von
                        MediSoftware Zugriff.</p>
                    <p>&nbsp;</p>
                    <p><strong>5 Auskunfts- und Widerrufsrecht</strong></p>
                    <p>Der Nutzer erh&auml;lt jederzeit ohne Angabe von Gr&uuml;nden kostenfrei Auskunft &uuml;ber seine
                        bei MediSoftware gespeicherten Daten. Er kann jederzeit seine bei MediSoftware erhobenen Daten
                        sperren, berichtigen oder l&ouml;schen lassen. Auch kann er jederzeit die etwaig gg&uuml;.
                        MediSoftware erteilte Einwilligung zur Datenerhebung und Verwendung ohne Angaben von
                        Gr&uuml;nden widerrufen.</p>
                    <p>Sollten Sie weitere Fragen haben, so k&ouml;nnen Sie sich gerne an uns wenden:</p>
                    <p>MediSoftware, Steinstra&szlig;e 1,</p>
                    <p>24118 Kiel, Tel. +49 (0)431 88687-0, E-Mail:&nbsp;<a href="mailto:medidate@medisoftware.de"
                                                                            rel="noreferrer nofollow noopener"
                                                                            target="_blank">medidate@medisoftware.de</a>.
                    </p>
                    <p>MediSoftware &uuml;berpr&uuml;ft regelm&auml;&szlig;ig und durchg&auml;ngig die Einhaltung dieser
                        Datenschutzbestimmungen und l&auml;sst sich hierzu von einer externen, unabh&auml;ngigen Firma
                        pr&uuml;fen (derzeit: ATOS, https://atos.net/de/deutschland). Erh&auml;lt MediSoftware formale
                        Beschwerdeschriften, wird sie mit dem Verfasser bez&uuml;glich seiner Bedenken Kontakt
                        aufnehmen, um eventuelle Beschwerden hinsichtlich der Verwendung von pers&ouml;nlichen Daten zu
                        l&ouml;sen. MediSoftware verpflichtet sich, dazu kooperativ mit den entsprechenden
                        Beh&ouml;rden, einschlie&szlig;lich lokaler Datenschutzbeh&ouml;rden, zusammenzuarbeiten.</p>
                    <p>Ein Angebot der MediSoftware</p>
                    <p>Steinstra&szlig;e 1,</p>
                    <p>24118 Kiel,</p>
                    <p>Deutschland</p>
                    <p>Telefon: +49 (0)431 88687-0</p>
                    <p>Fax: +49 (0)431 88687-88</p>
                    <p>E-Mail:&nbsp;<a href="mailto:medidate@medisoftware.de" rel="noreferrer nofollow noopener"
                                       target="_blank">medidate@medisoftware.de</a></p>
                    <p>Website: www.medisoftware.de</p>
                    <p>Vertretungsberechtigt: Dipl.Phys.Jan Meincke</p>
                    <ol start="1" type="1">
                        <li>UStID: DE134835986</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>VERWENDUNG UNSERES KONTAKTFORMULARS</strong></p>
                    <p>Unsere Internetseite nutzt form.taxi, einen Webdienst der Webseite&nbsp;<a
                        href="https://form.taxi/" rel="noreferrer nofollow noopener"
                        target="_blank">https://form.taxi</a> (nachfolgend &quot;form.taxi&quot;). Um Ihnen die
                        Funktionalit&auml;t des Formulars zur Verf&uuml;gung zu stellen, senden wir die von Ihnen
                        angegebene Daten an form.taxi. Diese Daten werden dort verarbeitet, gespeichert und an uns per
                        E-Mail weitergegeben. Au&szlig;erdem werden von form.taxi unter anderem weitere Daten wie Ihre
                        IP-Adresse, Ihr Typ des Browsers, die Domain der Webseite, das Datum und die Zeit des Zugriffs
                        erhoben, um die gew&uuml;nschte Funktionalit&auml;t des Formulars bereitzustellen.
                        Rechtsgrundlage f&uuml;r die Nutzung von form.taxi ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO
                        (berechtigtes Interesse). Die Datenverarbeitung und Speicherung erfolgt innerhalb der
                        Europ&auml;ischen Union. Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von
                        form.taxi:&nbsp;<a href="https://form.taxi/de/privacy" rel="noreferrer nofollow noopener"
                                           target="_blank">https://form.taxi/de/privacy</a>.</p>
                    <p>&nbsp;</p>
                    <p><strong>AKTUALIT&Auml;T UND &Auml;NDERUNG DER DATENSCHUTZERKL&Auml;RUNG&nbsp;</strong></p>
                    <p>Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den Stand 15.11.2018</p>
                    <p>Durch gesetzliche Vorgaben oder Weiterentwicklung der Website und der Angebote &uuml;ber die
                        Website kann es notwendig sein diese Datenschutzerkl&auml;rung zu &auml;ndern. Die jeweils
                        aktuelle Version ist jederzeit auf der Website vorhanden.</p>
                    <p>&nbsp;</p>
                    <p><strong>DIE ANSCHRIFT DER ZUST&Auml;NDIGEN AUFSICHTSBEH&Ouml;RDE LAUTET:&nbsp;</strong></p>
                    <p>Marit Hansen, ULD &ndash; Unabh&auml;ngiges Landeszentrum f&uuml;r Datenschutz Schleswig
                        Holstein</p>
                    <p>E-Mail:&nbsp;<u><a href="mailto:mail@datenschutzzentrum.de" rel="noreferrer nofollow noopener"
                                          target="_blank">mail@datenschutzzentrum.de</a></u></p>
                    <p>Holstenstra&szlig;e 98</p>
                    <p>24103&nbsp;Kiel</p>
                    <p>Telefon: 0431 988-1200&nbsp;<br/>&nbsp;Fax: 0431 988-1223</p>
                </div>
            </div>
        </section>
    </Layout>
)

export default Impress